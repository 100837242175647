<template>
  <div class="home">

    <div class="auth-wrap">

      <h2>Account anlegen</h2>

      <form @submit="sign_up" class="form" method="post">

        <div class="form-wrap form-wrap-first">
          <label for="email">E-Mail</label>
          <input v-model="user.email" class="form-input" type="email" name="email" id="email">
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name">Vorname</label>
              <input v-model="user.first_name" class="form-input" type="text" name="first_name" id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name">Nachname</label>
              <input v-model="user.last_name" class="form-input" type="text" name="last_name" id="last_name">
            </div>
          </div>
        </div>

        <div class="form-wrap form-wrap-last">
          <label for="password">Passwort</label>
          <input v-model="user.password" class="form-input" type="password" name="password" id="password">
        </div>

        <p>
          <input type="submit" value="Registrieren" class="button button-orange button-100">
        </p>
        <p>
          <router-link to="/auth/sign_in" class="button button-grey button-100">Einloggen</router-link>
        </p>

      </form>

    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'home',
  data () {
    return {
      user: {},
    }
  },
  methods: {
    sign_up: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/auth/sign_up', {
        email: this.user.email,
        password: this.user.password,
        first_name: this.user.first_name,
        last_name: this.user.last_name
      }, { headers: {} })
      .then(response => {
        console.log(response);
        this.$router.push('/auth/sign_in');
      })
      .catch(error => {
        this.$notify({
          group: 'alert',
          type: 'error',
          title: 'Registrierung fehlgeschlagen',
          text: error.response.data.errors
        });
      })

    },
  },
  mounted () {}
}
</script>

<style lang="scss">

  .auth-wrap {
    padding: 20px;
    width: 500px;
    margin: auto;
    text-align: center;

    h2 {
      font-weight: 400;
      font-size: 34px;
    }
  }


</style>
